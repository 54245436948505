::-webkit-scrollbar {
  height: 5px;
  width: 10px;
  background-color: #0e1525;
}

::-webkit-scrollbar-thumb {
  background-color: #cc1616;
  border-radius: 50px;
  height: 200px;
}
body {
  font-family: "Montserrat", sans-serif;
  color: #444444;
}

a,
Link {
  color: #cc1616;
  text-decoration: none !important;
}

a:hover,
Link:hover {
  color: #e82d2d;
  text-decoration: none;
}

.text-red {
  color: #fff !important;
  background-color: #cc1616 !important;
}
.text-blue {
  color: #fff !important;
  /* background-color: #cc1616 !important; */
  background-image: linear-gradient(270deg, #0f4392 0%, #001d3d 100%);
}
.text-topbar a:hover {
  color: #cc1616 !important;
}
/* .text-red:hover {
  color: #cc1616 !important;
  background-color: #fff !important;
  border: #cc1616 1px solid !important;
} */

.text-red-link {
  color: #cc1616 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #cc1616;
  width: 40px;
  height: 40px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #e72323;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #cc1616;
  border-top-color: #efefef;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #fff;
  font-size: 15px;
  height: 40px;
  padding: 0;
  color: #cc1616;
}

#topbar .contact-info a {
  line-height: 0;
  color: #cc1616;
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  color: #fff;
}

#topbar .contact-info i {
  color: #cc1616;
  line-height: 0;
  margin-right: 5px;
}

#topbar .contact-info .phone-icon {
  margin-left: 15px;
}

#topbar .social-links a {
  color: rgba(255, 255, 255, 0.6);
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover {
  color: #fff;
}

@media (max-width: 768px) {
  #topbar {
    font-size: 8px;
  }
}

.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: contents;
  }
  .logo a {
    margin-top: 10px;
  }
  .logo-img1 {
    width: 70px;
    height: 70px;
  }
  .logo-img2 {
    width: 70px;
    height: 70px;
  }
  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  display: none;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 55px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile .navbar-nav .nav-link.active {
  color: #000;
}
.navbar-nav .nav-link.active{
  color: #fff !important;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #0d6efd;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .nav-btn-mobile {
  background-color: #0d6efd;
  color: white;
}

.navbar-mobile .nav-btn-mobile:hover {
  background-color: #0d6efd;
  color: white;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(270deg, #0f4392 0%, #001d3d 100%);
  position: relative;
  overflow: hidden;
  isolation: isolate;
}
.bg-pattern {
  position: absolute;
  top: 50%;
  left: 40%;
  right: 0;
  translate: 0 -50%;
  min-height: 100%;
  pointer-events: none;
  z-index: -2;
}
.hero-banner {
  z-index: 1;
  top: 1%;
  margin-top: -50px;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.hero-sell {
  /* background-color: #fff; */
  color: #fff;
  position: absolute;
  top: 65%;
  margin-left: 110px;
  width: 50%;
}
.hero-button1 {
  background-color: #fff;
  width: min-content;
}
.hero-button2 {
  /* background-color: #fff; */
  color: #fff;
  position: absolute;
  top: 25%;
  margin-left: 110px;
  width: 50%;
}
.hero-button3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.hero-button {
  /* background-color: #fff; */
  color: #cc1616;
  position: absolute;
  top: 40%;
  margin-left: 120px;
  width: 50%;
}
.hero-button span {
  font-size: 16px;
}
.hero-button input,
.hero-button select {
  border: none !important;
}
.text-justify {
  text-align: justify;
}
#hero h1 {
  /* margin: 0 0 10px 0; */
  font-size: 36px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}

#hero h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: #cc1616;
  border-color: #cc1616;
}

.hero-img {
  display: flex;
  flex-direction: row;
}
.adv {
  color: #fff;
  position: absolute;
  margin-left: 110px;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    text-align: center;
    height: 100vh;
    /* padding-top: -50px; */
  }

  #hero .container {
    /* padding-top: -40px; */
  }

  #hero h1 {
    font-size: 20px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero p {
  }

  .hero-img {
    display: flex;
    flex-direction: column !important;
  }

  .hero-button2 {
    width: 80%;
    text-align: justify;
    margin-left: 20px;
    top: 20%;
  }

  .hero-sell {
    width: 80%;
    text-align: justify;
    margin-left: 20px;
  }

  .hero-button {
    width: 80%;
    margin-left: 20px;
    top: 30%;
    text-align: center;
  }
  .hero-button3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hero-button3 button {
    margin-top: 10px;
  }

  .hero-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .estate .icon-box {
    width: 90%;
  }

  .login-form {
    width: fit-content !important;
    margin-left: 30px !important;
    top: 20% !important;
  }

  .login-form input {
    width: 200px !important;
  }

  .register-form {
    width: fit-content !important;
    margin-left: 15px !important;
    top: 10% !important;
    padding-bottom: 50px !important;
  }

  .register-form input {
    width: 250px !important;
  }
  .form-content {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .register-hero {
    overflow-y: scroll !important;
  }

  .adv {
    margin-left: 30px;
    margin-top: -20px;
  }

  .navbar-nav .nav-link.active{
    color: #000 !important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .section-title span {
    font-size: 38px;
  }
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #cc1616;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding-top: 10px;
}

.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #ed5b5b;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #191919;
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover {
  background: #cc1616;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #ed5b5b;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #191919;
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .bg-box1 {
  background: #cc1616;
  /* padding: 30px 30px 70px 30px; */
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
  color: #fff;
}

.why-us .bg-box1 h4,
.why-us .bg-box1 span,
.why-us .bg-box1 p {
  color: #fff !important;
}

.why-us .box:hover span,
.why-us .box:hover h4,
.why-us .box:hover p {
  color: #fff;
}

.clients {
  background: #cc1616;
  padding: 15px 0;
  text-align: center;
}

/* .clients img {
  width: 50%;
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.clients img:hover {
  filter: none;
  transform: scale(1.2);
} */

@media (max-width: 768px) {
  .clients .clients-loc {
    display: flex;
    flex-direction: column;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #cc1616;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
  position: relative;
  z-index: 2;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: rgba(204, 22, 22, 0.15);
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
  z-index: -1;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #191919;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  background: #cc1616;
  border-color: #cc1616;
  padding: 70px 20px 90px 20px;
}

.services .icon-box:hover .icon i {
  color: #cc1616;
}

.services .icon-box:hover .icon::before {
  background: rgba(255, 255, 255, 0.3);
}

.services .icon-box:hover h4,
.services .icon-box:hover p {
  color: #fff !important;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)),
    url("./images/cta-bg.jpg") fixed center center;
  background-size: cover;
  padding: 120px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #cc1616;
  border: 2px solid #cc1616;
}

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #cc1616;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f5a0a0;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: #cc1616;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #cc1616;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #e82d2d;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #efefef;
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #0c0c0c;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: #191919;
  color: #fff;
  border-top: 4px solid #cc1616;
  text-align: center;
  padding: 30px 20px;
}

#footer .footer-top .footer-info h3 {
  font-size: 36px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #262626;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #cc1616;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ed5b5b;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #eb4444;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #cc1616;
  color: #fff;
  transition: 0.3s;
  border-radius: 4;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #e82d2d;
}

#footer .copyright {
  border-top: 1px solid #262626;
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#footer .credits a {
  color: #e82d2d;
}

@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}
.bg-custom {
  /* background-color: #000 !important; */
  background-image: linear-gradient(270deg, #0f4392 0%, #001d3d 100%);
  /* background: rgb(243, 0, 21);
  background: linear-gradient(
    90deg,
    rgba(243, 0, 21, 1) 2%,
    rgba(204, 22, 22, 0.9192927170868347) 100%
  ); */
  color: #fff !important;
}
.bg-custom1 {
  background-color: #cc1616 !important;
  /* background-image: linear-gradient(270deg, #0f4392 0%, #001d3d 100%); */
  /* background: rgb(243, 0, 21);
  background: linear-gradient(
    90deg,
    rgba(243, 0, 21, 1) 2%,
    rgba(204, 22, 22, 0.9192927170868347) 100%
  ); */
  color: #fff !important;
}
#intro {
  background-image: url("./images/bg-login.jpg");
  height: 200vh;
  background-color: #fff !important;
}
.buy-height {
  height: 170vh !important;
}
.sell-height {
  height: 205vh !important;
}
@media (min-width: 1024px) {
  .buy-height {
    height: 190vh !important;
  }
  .sell-height {
    height: 205vh !important;
  }
}
#intro1 {
  background-image: url("./images/bg-login.jpg");
  height: max-content;
}
/* #intro1:before {
  content: "";
  background: rgba(6, 12, 34, 0.537);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
} */
.bg-box {
  background-color: #cc1616;
}
.heading {
  font-size: 32px !important;
  line-height: 35px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.sell-pading {
  margin-top: 50px;
  /* padding-top: 50px; */
  padding-bottom: 50px;
  background-color: #1f16cc0e;
  border-radius: 15px;
}
.card-title {
  border-radius: 15px;
}
.card-padding {
  /* padding-top: 100px; */
  margin-top: 50px;
}
.sell {
  flex-direction: row;
  justify-content: start;
  align-items: start;
}
@media (max-width: 768px) {
  .sell {
    flex: none;
    flex-direction: column;
    padding-bottom: 150px;
  }
  .sell-height {
    height: 310vh !important;
  }
  .buy-height {
    height: 290vh !important;
  }
  .card-start {
    align-items: center;
    justify-content: center;
  }
  .card-padding {
    padding-top: 50px;
  }
  .nav-margin {
    margin-top: -10px !important;
    justify-content: center !important;
  }
  .hero-box {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .login input {
    width: fit-content !important;
  }
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header-img {
  width: 200px;
  height: 100px;
}
.header1-img {
  width: 200px;
  height: 80px;
}
.footer-img {
  width: 200px;
  height: 100px;
}
.navbar1 {
  height: 90px;
}
.text-bold {
  font-weight: 700;
}
.nav-margin {
  margin-top: 150px;
  justify-content: space-between !important;
}
.hero-box {
  display: flex;
  flex-direction: row !important;
  /* justify-content: center !important; */
}
.filter {
  background-color: #fff;
}
.bg-border-custom {
  width: 100%;
  background-color: #cc1616;
}
.paper {
  display: flex;
  flex-direction: row;
}

.form-group label {
  font-size: 20px;
  font-weight: 500;
}

.estate .icon-box {
  background-color: #fff;
  padding: 20px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.estate .primary-image {
  border: 1px solid #e0eded;
  padding: 5px;
}

.estate .thumbnail-images {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
}

.estate .thumbnail-images a {
  display: table-cell;
  width: 100px;
  padding: 5px;
  border: 1px solid #e0eded;
  border-top: 0;
}

.estate .thumbnail-images a img {
  width: 100%;
}

.estate .product-info h3 {
  font-weight: bold;
  font-size: 18px;
}

.estate .product-info h4 {
  font-weight: bold;
  font-size: 15px;
}

.product-info h3 {
  margin-bottom: 10px;
}

.wp-block.property.list {
  border: 1px solid #e0eded;
}

.wp-block {
  margin: 0 0 15px 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  cursor: default;
  border-radius: 2px;
}

.no-border {
  border: 0 !important;
}

.wp-block.property.list .wp-block-content .content-title {
  font-size: 20px;
  color: #3498db;
  margin-bottom: 5px;
}

.wp-block.property.list .wp-block-content .description {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0eded;
}

.mb-15 {
  margin-bottom: 15px;
}

.wp-block.property.list .wp-block-content .price {
  font-size: 22px;
  color: #cc1616;
  font-weight: 600;
}

.wp-block.property.list .wp-block-content .period {
  margin-left: 5px;
  color: #999;
}

.wp-block.property.list .wp-block-content .capacity {
  font-size: 16px;
  color: #999;
}

.mt-15 {
  margin-top: 15px;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info {
  display: table;
  width: 100%;
  border: 1px solid #e0eded;
  border-radius: 10px;
}

.wp-block.property.list .wp-block-footer ul.aux-info {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  background: #fcfcfc;
  border-top: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li {
  padding: 10px 10px;
  font-size: 11px;
  text-align: center;
}

.wp-block.property.list .wp-block-footer ul.aux-info li {
  display: table-cell;
  padding: 10px 15px;
  vertical-align: middle;
  border-right: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li i {
  display: block;
}

.wp-block.property.list .wp-block-footer ul.aux-info li i {
  font-size: 18px;
  margin-right: 8px;
  color: #333;
}

.rating span.star {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  float: left;
  padding: 0 1px;
  cursor: pointer;
}

p {
  line-height: 22px;
  margin-bottom: 10px;
}

ol,
ul {
  list-style: none;
}

.tabs-framed {
  border: 1px solid #e0eded;
}

.tabs li a {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding: 12px 25px;
  margin: 0 -1px;
  border-bottom: 3px solid transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.boxed {
  background: #fff;
  border: 1px solid #e0eded;
  position: relative;
}

.tabs {
  border-bottom: 1px solid #e0eded;
}

.tabs li {
  float: left;
  border-right: 1px solid #e0eded;
  margin-bottom: -1px;
}

.tab-body {
  padding: 15px;
}

.estate-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.btn-custom {
  border-radius: 15px;
  width: fit-content;
  height: max-content;
  padding: 5px 10px;
  border: none;
}

.btn-custom:hover {
  background-color: transparent !important;
  border: #cc1616 solid 1px;
}

.btn-custom1 {
  border-radius: 15px;
  /* width: 300px !important; */
  height: max-content;
  padding: 5px 10px;
  border: none;
}

.btn-custom1:hover {
  background-color: transparent !important;
  border: #cc1616 solid 1px !important;
  color: #cc1616 !important;
}

.sell input {
  height: 50px;
}

.sell select {
  height: 50px;
}

.register-pattern {
  margin-top: 50px;
}
.register-form {
  margin-top: 50px;
  width: max-content !important;
  position: absolute;
  top: 15%;
  margin-left: 80px;
}

.register-form input {
  height: 50px;
  width: 230px;
}
.login-form input {
  width: 300px;
  height: 50px;
}
.form-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.login-form .form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-form {
  margin-top: 50px;
  width: max-content !important;
  position: absolute;
  top: 25%;
  margin-left: 80px;
}

.glow-button {
  text-decoration: none;
  /* background-color: #004A7F; */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}
.carousel-image {
  width: 400px;
  height: 300px;
  overflow: hidden;
}
.carousel-image1 {
  width: 380px;
  height: 200px;
  overflow: hidden;
}

.filters {
  height: max-content;
}

.filter-card {
  height: max-content;
}

#filters {
  background-color: #cc1616;
}

.bedrooms input[type="checkbox"] {
  display: none;
}

.bedrooms-title {
  font-size: 16px;
  font-weight: 400;
}

.filter-button {
  border: 1px solid #ebecf0;
  color: #42526e;
  border-radius: 50px;
  margin-top: 10px;
  cursor: pointer;
}

.filter-button:hover {
  border: 1px solid #00000048;
  border-radius: 50px;
  margin-top: 10px;
  background-color: #cc161621;
}

.filter-button input[type="checkbox"]:checked + span {
  /* border: 1px solid #00000048; */
  border: none;
  border-radius: 50px;
  background-color: #cc1616;
  color: #fff;
}

#footer1 {
  padding-bottom: 20px;
}
#footer1 .copyright {
  text-align: center;
  padding-top: 10px;
}

#footer1 .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #000;
}

#footer1 .credits a {
  color: #e82d2d;
}

@media (max-width: 575px) {
  #footer1 .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}
